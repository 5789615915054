import countryList from '@/utils/tools/country'
import { rules } from '@/utils/tools/checkout'
import { i18n } from '@/i18n'
import { deepClone } from '@/utils/tools/utils'
import { getStateList } from '@/views/checkout/api'

const formSaveRules = {
  cardNumber: [
    {
      required: true,
      whitespace: true,
      message: i18n.t('message.common.inputPlaceholder', {
        field: i18n.t('message.checkout.cardNumber').toLowerCase()
      })
    },
    { validator: rules.cardNumber, trigger: 'blur' }
  ],
  expiredDate: [
    {
      required: true,
      whitespace: true,
      message: i18n.t('message.common.inputPlaceholder', {
        field: i18n.t('message.checkout.expiredDate').toLowerCase()
      })
    },
    { validator: rules.monthYear, trigger: 'blur' }
  ],
  cvv: [
    {
      required: true,
      whitespace: true,
      message: i18n.t('message.common.inputPlaceholder', {
        field: i18n.t('message.checkout.cvv').toLowerCase()
      })
    },
    { validator: rules.cvv, trigger: 'blur' }
  ],
  cardFirstName: [
    {
      required: true,
      whitespace: true,
      message: i18n.t('message.common.inputPlaceholder', {
        field: i18n.t('message.checkout.cardName').toLowerCase()
      })
    }
  ],
  cardLastName: [
    {
      required: true,
      whitespace: true,
      message: i18n.t('message.common.inputPlaceholder', {
        field: i18n.t('message.checkout.cardName').toLowerCase()
      })
    }
  ],
  email: [
    {
      type: 'email',
      message: i18n.t('message.checkout.correctEmailAddressTip')
    }
  ]
}

export const showDocument = (country) => {
  switch (country) {
    case 'BR': {
      return 'CPF'
    }
    case 'CL': {
      return 'RUT'
    }
    case 'PE': {
      return 'DNI'
    }
    case 'ZA': {
      return 'ID / Passport'
    }
    default: {
      return ''
    }
  }
}
/**
 * 初始化区号
 */
export const initPhoneCode = (_this, country) => {
  const obj = {}
  countryList.forEach((item) => {
    obj['+' + item.phone_code] = '+' + item.phone_code
    if (country === item.code && !_this.form.phone.includes('+')) {
      _this.form.phoneCode = '+' + item.phone_code
    }
  })
  if (!_this.form.phone.includes('+')) {
    if (!_this.form.phoneCode) {
      _this.form.phoneCode = '+254'
    }
  }
  _this.phoneCodeList = Object.keys(obj)
}

export const initBankList = (selectMethod, country) => {
  if (selectMethod && selectMethod.child && selectMethod.child.length > 0) {
    const methodSubItem = selectMethod.child.find((item) => item.country === country)
    return methodSubItem ? methodSubItem?.nameCode || [] : []
  }
  return []
}

// 初始化可用的支付方式
export const initCanUseMethod = (_this, country) => {
  const methodList = _this.orderDetails?.paymentLogos
  const array = []
  if (methodList && methodList.length > 0) {
    methodList.forEach((item) => {
      if (item.supportCountry && (item.supportCountry.includes('ALL') || item.supportCountry.includes(country))) {
        array.push(item)
      }
    })
  }
  if (
    !array[_this.choosedPaymentMethodIndex] ||
    array.length < _this.choosedPaymentMethodIndex ||
    _this.choosedPaymentMethod?.cardName !== array[_this.choosedPaymentMethodIndex]?.cardName
  ) {
    _this.choosedPaymentMethodIndex = 0
    _this.choosedPaymentMethod = array[0]
  }
  _this.disable = array <= 0
  return array
}

// 设置requiredFields
export const initRequiredFields = (_this, selectMethod) => {
  if (selectMethod && selectMethod.requiredFields && _this.orderDetails.checkoutParams) {
    _this.displayItems = {}
    _this.formRules = deepClone(formSaveRules)
    const paramsList = _this.orderDetails.checkoutParams.concat(selectMethod.requiredFields)
    paramsList.forEach((param) => {
      _this.displayItems[param] = true
      if (selectMethod.requiredFields.includes(param)) {
        if (param === 'email') {
          _this.formRules[param] = [
            {
              required: true,
              whitespace: true,
              type: 'email',
              message: i18n.t('message.checkout.correctEmailAddressTip')
            }
          ]
        } else {
          _this.formRules[param] = [
            {
              required: true,
              whitespace: true,
              message: _this.$t('message.common.inputPlaceholder', {
                field: _this.$t(`message.checkout.${param}`).toLowerCase()
              })
            }
          ]
        }
      }
    })
    if (selectMethod?.child && selectMethod?.child.length > 0) {
      _this.formRules['extension.code'] = [
        {
          required: true,
          whitespace: true,
          message: _this.$t('message.common.selectPlaceholder', {
            field: _this.$t(getSubSelectMethodBackName(selectMethod)).toLowerCase()
          })
        }
      ]
    }
  } else {
    if (_this.orderDetails.checkoutParams && _this.orderDetails.checkoutParams.includes('country')) {
      _this.displayItems.country = true
    }
  }
}

export const initExtensionRules = (_this, value) => {
  const obj = { code: value }
  if (value) {
    const item = _this.bankList.find((item) => item.code === value)
    _this.bankTemplateList = item?.data || []
    _this.bankTemplateList.forEach((item) => {
      obj[item.param] = ''
    })
    _this.form.extension = obj
    _this.bankTemplateList.forEach((item) => {
      _this.formRules[`extension.${item.param}`] = [
        {
          required: true,
          whitespace: true,
          message: _this.$t('message.common.inputPlaceholder', {
            field: item.title
          })
        }
      ]
    })
  } else {
    _this.bankTemplateList = []
  }
}

// select 框搜索过滤
export const isFilterOption = (value, option) => {
  if (option?.key) {
    return option?.key.toUpperCase().includes(String(value).toUpperCase())
  }
  return false
}

// 获得国家文本国际化 用于显示
export const getCountryNameText = (country, app) => {
  // return country?.translations[app.currentLan] || country.name
  return country.name
}

// 得到一个默认的账单国家用于筛选支付方式(作用是保持原有逻辑不变)
export const getDefaultCountry = (orderDetails) => {
  let country = orderDetails?.billing?.country
  if (country) {
    return country
  } else {
    if (orderDetails?.topCountry && orderDetails?.topCountry.length > 0) {
      country = orderDetails?.topCountry[0]
    } else {
      if (orderDetails?.paymentLogos && orderDetails?.paymentLogos.length > 0) {
        if (orderDetails?.paymentLogos[0]?.supportCountry && orderDetails?.paymentLogos[0]?.supportCountry.length > 0) {
          country = orderDetails?.paymentLogos[0]?.supportCountry[0]
          // 当国家是all的情况
          if (country === 'ALL') {
            if (orderDetails?.showOtherCountry) {
              country = 'US'
            } else {
              country = ''
            }
          }
        }
      }
    }
  }
  return country
}

// 初始化国家下拉框列表
export const initCountryOptions = (_this) => {
  const list = _this.orderDetails.topCountry || []
  _this.putTopCountryList = []
  _this.otherCountryList = []
  if (list.length > 0) {
    _this.putTopCountryList = countryList.filter((item) => list.includes(item.code))
  }
  if (_this.orderDetails.showOtherCountry) {
    _this.otherCountryList = countryList.filter((item) => !list.includes(item.code))
  } else {
    // 如果top国家没有确定那么就把这个国家添加进去
    const countryCode = _this.orderDetails?.billing?.country
    if (countryCode && !_this.putTopCountryList.some((item) => item.code === countryCode)) {
      _this.putTopCountryList.unshift(countryList.find((item) => item.code === countryCode))
    }
  }
}
export const initTopMethodList = (_this) => {
  _this.topMethodsIndex = []
  _this.topMethods = _this.canSelectMethods
  if (_this.topMethods.length > 3) {
    _this.topMethods = _this.topMethods.slice(0, 3)
  }
  for (let i = 0; i < _this.topMethods.length; i++) {
    _this.topMethodsIndex.push(i)
  }
}

/**
 * 保持时间显示为两位数
 * @param {*} second
 * @returns
 */
export const initSecondDisplay = (second) => {
  if (String(second).length === 1) {
    return `0${second}`
  } else {
    return second
  }
}

export const isShowEmoji = () => {
  return navigator.platform.indexOf('Win') !== 0
}

export const stateControl = (_this, country) => {
  _this.form.state = ''
  _this.stateDisable = true
  getStateList(country).then((res) => {
    _this.stateList = res.data
    _this.stateDisable = false
  })
}

export const documentControl = (_this, country) => {
  if (_this.formRules.document) {
    const countryList = ['AR', 'BR', 'CL', 'CO', 'ZA', 'UY', 'PY', 'PA', 'GT']
    _this.formRules.document[0].required = countryList.includes(country)
    _this.refreshFormItem = false
    _this.$nextTick(() => {
      _this.refreshFormItem = true
    })
  }
}

// 获取子选择框的 label
export const getSubSelectMethodBackName = (selectMethod) => {
  if (selectMethod) {
    switch (selectMethod.code) {
      case 'MOBILEMONEY':
        return 'message.checkout.mobileMoneyOperator'
      case 'USSD':
        return 'message.checkout.bank'
      default:
        return ''
    }
  }
  return ''
}

// 交易结果统一处理
export const toTransactionResult = (_this, res, id, isQrCode = false) => {
  let name = ''
  if (isQrCode && +res.status === 200) {
    return
  }
  if (+res.status === 100) {
    name = 'CheckoutSuccess'
  } else if (+res.status === 102) {
    if (!isQrCode) {
      name = 'CheckoutPending'
    }
  } else {
    name = 'CheckoutFailed'
  }
  if (name) {
    _this.$router.push({
      name,
      params: { id }
    })
  }
}
